/* Poppins font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Poppins&family=Roboto&family=Ubuntu&display=swap");

.App {
  font-family: "Poppins", sans-serif;
}

.navbar {
  background-color: #a7defc;
}

.section {
  padding-top: 80px;
}

.section2 {
  padding-top: 30px;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.alt-text {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-style: italic;
}

.parent {
  padding-bottom: 10px;
}

#freestyle {
  width: 75%;
  height: 75%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#machine {
  width: 35%;
  margin-top: 15px;
}

#screen-1 {
  width: 80%;
  margin-top: 15px;
}

#screen-2 {
  width: 80%;
  margin-top: 15px;
}

.persona {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
  width: 50%;
}

p {
  margin: 0px 0px 0px 0px;
}

.card-col {
  display: flex;
  margin-bottom: 24px;
}

.card {
  align-items: stretch;
  width: 100%;
}

.storyboard-panel {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.line {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
